import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/media',
    name: 'Media',
    component: () => import( '../views/Media.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import( '../views/About.vue')
  },
  {
    path: '/game/:game',
    name: 'Game',
    props : true,
    component: () => import( '../views/Game.vue')
  },
  {
    path: '/project/:project',
    name: 'Project',
    props : true,
    component: () => import( '../views/Project.vue')
  },
  {
    path: '/games',
    name: 'Games',
    component: () => import( '../views/Games.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import( '../views/Contact.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import( '../views/Privacy.vue')
  },
  {
    path: '/team',
    name: 'Team',
    component: () => import( '../views/Team.vue')
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
