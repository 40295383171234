<template>
  <div class="bulletinboard">
    <div class="bulletinpost">
      <div class="headertext">Snakebird Complete is out!</div>
      <div class="regulartext">Partnering with our friends at <a href="https://www.astragames.org/" >Astra Logical</a> we've released Snakebird Complete on the <a href="https://www.nintendo.com/us/store/products/snakebird-complete-switch/" >Nintendo Switch.</a> Snakebird Complete is a combined version of Snakebird and Snakebird Primer with a few quality-of-life additions. If you’ve never played Snakebird before then Snakebird Complete is the definitive version to pick up.</div>
    </div>
      <div class="headertext">Also currently working on:</div>
      <br>
    <div class="bulletinpost">
      <div class="headertext">Unannounced side project</div>
      <div class="regulartext">A fast-paced action platformer with a high skill ceiling. A bit different from our previous games in that it's not puzzly and instead primarily focuses on mechanical skill and speed.</div>
    </div>
    <!--<div class="bulletinpost">
      <div class="headertext">Part-time outsourcing</div>
      <div class="regulartext">Building a 3D visualization system for web browsers.</div>
    </div>-->
  </div>
  <!--<div class="tinynoumenonlogo">
    <img src="/img/logo_noumenon.png" />
    <p>Noumenon Games</p>
  </div>
  <div class="about">
    <p>Founded in 2009 by two college friends and later reinforced by a third after a stint in the AAA arena, Noumenon Games is a hermit game studio that loves exploring and developing novel ideas. Between projects we tend to end up outsourcing ourselves to other studios supporting them in programming, 3D animation, game and level design or art.</p>
  </div>-->
  <div class="imagegrid">
    <div><router-link to="/game/nimbus"><img src="/img/logo_nimbus.png" /></router-link></div>
    <div><router-link to="/game/snakebird"><img src="/img/logo_snakebird.png" /></router-link></div>
    <div><router-link to="/game/snakebird_primer"><img src="/img/logo_snakebird_primer.png" /></router-link></div>
    <div><router-link to="/game/snakebird_complete"><img src="/img/logo_snakebird_complete.png" /></router-link></div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home'
/*  components: {
    HelloWorld
  }*/
}
</script>
