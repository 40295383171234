<template>
  <div id="header">
    <div>
      <img src="/img/logo_noumenon.png" />
    </div>
  </div>
  <div id="nav">
    <div id="line" />
    <div id="menu">
      <ul>
        <li><router-link to="/">Home</router-link></li>
        <li><router-link to="/games">Games</router-link></li>
        <li><router-link to="/media">Media</router-link></li>
        <li><router-link to="/about">About</router-link></li>
        <li><router-link to="/contact">Contact</router-link></li>
        <li><router-link to="/privacy">Privacy policy</router-link></li>
      </ul>
    </div>
  </div>
  <router-view />
  <div id="footer">
    <div><a href="https://x.com/noumenongames"><img src="/img/logo_x.png"></a></div>
    <p>Copyright 2024 Noumenon Games</p>
  </div>
</template>

<script>
</script>
<style lang="scss">
  a
  {
    color: rgb(187, 95, 255)
  }
  a:visited
  {
    color: darkgray;
  }
  body
  {
    overflow-x: hidden;
    background-image: url("/background/background_test.png");
    background-repeat: repeat-x;
    background-color: #140c1c;
    margin: 0 auto;
    color: white;
    font-family: 'Open Sans', sans-serif;
  }
  #header
  {
    $child_dims: 530px;
    text-align: center;
    margin: 0 auto;
    transform: translate( calc(50% - $child_dims / 2) );
    margin-top: -200px;
    margin-bottom: -200px;
    pointer-events: none;
    width: 100%;
    div
    {
      height: $child_dims;
      width: $child_dims;
      background: radial-gradient( closest-side, rgba(255,255,255,0.2), rgba(255,255,255,0) ) center no-repeat;
      animation: logo_grad 2s ease-in-out infinite;
      margin : 0;
      position: relative;
      img
      {
        width: 88px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .page
  {
    margin: 0 auto;
    width: 100%;
    max-width: 900px;
    margin-top: 75px;
    margin-bottom: 75px;
    text-align: center;
  }
  .imagegrid
  {
    width: 95%; //save 5% for margins when squished
    max-width: 850px;
    margin: auto auto;
    margin-top: 50px;
    margin-bottom: 75px;
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 50px;
    grid-row-gap: 0px;
    justify-items: center;
    align-items: center;
      img
      {
        width: 100%;
        max-width: 400px;
        margin-top: -20px;
      }
  }
  .about
  {
    position: relative;
    margin: 0 auto;
    margin-bottom: 75px;
    width: 90%;
    max-width: 900px;
    text-align: justify;
  }
  .gamepagelogo
  {
    margin: 0 auto;
    width: 100%;
    max-width: 900px;
    text-align: center;
      img
      {
        width: 100%;
        max-width: 400px;
        margin-top: -30px;
        margin-bottom: -50px;
      }
  }
  .gamepage
  {
    margin: 75px auto;
    width: 100%;
    max-width: 900px;
    text-align: center;
  }
  .bulletinboard
  {
    margin: 75px auto;
    //margin-top: 120px; // For manually positioning the blocks of text if there's not much of it (looks odd if there's only a few lines sitting high up).
    width: 100%;
    max-width: 500px;
    height: 600px;
  }
  .bulletinpost
  {
    margin-bottom: 40px;
    padding-bottom: 7px;
    border-left: 1px solid rgb(187, 95, 255);
  }
  .tinynoumenonlogo
  {
    margin: 0 auto;
    width: 100%;
    max-width: 900px;
    text-align: center;
    font-size: 30px;
    line-height: 0px;
      img
      {
        width: 96px;
      }
  }
  .headertext
  {
    margin: 0 auto;
    width: 90%;
    max-width: 900px;
    text-align: justify;
    font-size: 24px;
    //font-weight: bold;
  }
  .regulartext
  {
    margin: 0 auto;
    width: 90%;
    max-width: 900px;
    text-align: justify;
    //font-size: 20px;
  }
  @keyframes logo_grad
  {
    0% {
      background-size: 100% 100%;
    }
    50%
    {
      background-size: 70% 70%;
    }
    100%
    {
      background-size: 100% 100%;
    }
  }
  #nav
  {
    text-align: center;
    margin: 0 auto;
    #line
    {
      margin: 0 auto;
      width: 100%;
      height: 1px;
      background: linear-gradient(90deg, rgba(0,0,0,0), rgba( 187, 95, 255, 0.5), rgb( 255, 255, 255), rgba( 187, 95, 255, 0.5 ), rgba(0,0,0,0));
    }
    #menu
    {
      display: table;
      margin: 0 auto;
      margin-bottom: 100px;
      ul
      {
        list-style-type: none;
        margin: 0;
        padding: 0;
        li
        {
          text-transform: uppercase;
          white-space: nowrap;
          font-size: 20px;
          padding: 30px;
          display: inline;
          a
          {
            text-decoration: none;
            color: white;
            //color: rgb(187, 95, 255);
          }
          .router-link-active
          {
            //color: white;
            color: rgb(187, 95, 255);
            //color: rgb(17, 186, 255);
          }
        }
      }
    }
  }
  #footer
  {
    text-align: center;
    margin: 0 auto;
    margin-top: 125px;
    margin-bottom: 150px;
    div
    {
      img
      {
        height: 40px;
        margin-bottom: 20px; 
        //transform: scale( 25% );
      }
    }
  }
  @media screen and (max-width: 768px)
  {
    .imagegrid
    {
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
      justify-items: center;
      align-items: center;
        img
        {
          width: 100%;
          max-width: 600px;
          margin-top: -50px;
        }
    }
    .regulartext
    {
      max-width: 600px;
    }
    .headertext
    {
      max-width: 600px;
    }
    #nav
    {
    text-align: center;
    margin: 0 auto;
    #menu
    {
      display: table;
      margin: 0 auto;
      margin-bottom: 100px;
      ul
      {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-auto-rows: 40px;
        li
        {
          text-transform: uppercase;
          padding: 0px;
          a
          {
            text-decoration: none;
            color: white;
            //color: rgb(187, 95, 255);
          }
          .router-link-active
          {
            //color: white;
            color: rgb(187, 95, 255);
            //color: rgb(17, 186, 255);
          }
        }
      }
    }
  }
  }
</style>